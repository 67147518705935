body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.no-autocomplete {
    color: #999;
    padding: 8px;
}
.autocomplete {
    border: 1px solid #dbdbdb;
    border-radius: 0 0 4px 4px;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
}
.autocomplete li {
    padding: 8px;
}
.autocomplete > .active,
.autocomplete li:hover {
    background-color: #efefef;
    cursor: pointer;
    font-weight: 700;
}
